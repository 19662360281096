/* eslint-disable react/no-danger */
import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SectionTitle from '../SectionTitle'
import useQuery from '../../../hooks/useQuery'
import howItWorksQuery, { QueryHowItWorksResult } from '../../../graphql/howItWorksQuery'

export type Props = {
  testId?: string
  listId: string
}

type ImageLeftProps = {
  images: {
    mobile: string
    tablet: string
    desktop: string
  }
}

const { cssPrefix, toPrefix } = toCssPrefix('MomentHowItWorks__')

const Wrapper = styled.div`
  margin-bottom: 28px;
  padding: 64px 22px 0 23px;
  color: ${({ theme }) => theme.colors.brand.default};

  .${cssPrefix} {
    &inner {
      background: #f7f9fc;
      border-radius: 0 0 15px 15px;
      max-width: 330px;
      margin: 0 auto;
    }

    &right {
      padding: 0 23px 22px 24px;

      p {
        font-size: 17px;
        line-height: 25px;
        color: #354455;
        margin: 0;
      }
    }

    &text-icon {
      display: flex;
      padding-top: 16px;
      max-width: 290px;
      margin: 0 auto;

      img {
        width: 64px;
        height: 64px;
        margin-right: 16px;
        -webkit-user-drag: none;
        -moz-user-drag: none;
        -ms-user-drag: none;
        user-drag: none;
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    margin-bottom: 50px;
    padding: 64px 34px 0 23px;

    .${cssPrefix} {
      &inner {
        max-width: 711px;
      }

      &right {
        padding-top: 20px;
        padding-bottom: 48px;
      }

      &text-icon {
        max-width: 385px;

        img {
          width: 78px;
          height: 78px;
          margin-right: 32px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin-bottom: 44px;
    padding: 64px 62px 0 68px;

    .${cssPrefix} {
      &inner {
        border-radius: 15px;
        max-width: 1400px;
        display: flex;
      }

      &left,
      &right {
        width: 50%;
      }

      &right {
        padding: 23px 0 38px 0;
      }

      &text-icon {
        max-width: 375px;

        img {
          margin-right: 24px;
        }
      }
    }
  }
`

const ImageLeft = styled.div<ImageLeftProps>`
  width: 100%;
  height: 300px;
  background-image: url(${({ images }) => images.mobile});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    background-image: url(${({ images }) => images.tablet});
  }

  @media screen and (${breakpoints.desktop.min}) {
    background-image: url(${({ images }) => images.desktop});
    height: 100%;
    background-position: left;
  }
`

const HowItWorks = ({ listId, testId = 'how-it-works' }: Props) => {
  const { data, loading, error } = useQuery<QueryHowItWorksResult>(howItWorksQuery, { variables: { listId } })

  const orList = data?.orList
  const hows = orList?.itemsCollection.items

  if (!hows) return null

  const steps = hows[0]?.stepsCollection?.items

  if (!steps || error !== undefined || (!steps?.length && !loading)) return null

  return (
    <Wrapper data-testid={testId}>
      {orList?.title && <SectionTitle color={orList?.titleColor} text={orList?.title} {...orList} />}
      <div className={toPrefix('inner')}>
        <div className={toPrefix('left')}>
          <ImageLeft
            images={{
              desktop: hows[0]?.imageDesktop?.url,
              tablet: hows[0]?.imageTablet?.url,
              mobile: hows[0]?.imageMobile?.url,
            }}
          />
        </div>
        <div className={toPrefix('right')}>
          {steps.map((step) => (
            <div key={step.sys.id} className={toPrefix('text-icon')}>
              <img src={step?.icon?.url} alt="how it works" />
              <div>{documentToReactComponents(step.text.json)}</div>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default HowItWorks
