import { OrListFragment, HowItWorksFragment } from './fragments'
import { BaseBlock, BlockType, ClientSideHowItWorks } from './contentfulTypes'

export interface QueryHowItWorksResult {
  orList: {
    blockType: BlockType.HOW_IT_WORKS
    itemsCollection: {
      items: ClientSideHowItWorks[]
    }
  } & BaseBlock
}

export default `
  query HowItWorksQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
      itemsCollection {
        items {
        ...HowItWorksFragment
      }
    }
   }
  }
  ${OrListFragment}
  ${HowItWorksFragment}
`
