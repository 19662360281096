import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Image, breakpoints } from '@ecommerce/shared'

interface Props {
  images: Array<{ url: string }>
  className?: string
}

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 94px;
  padding: 24px 0;
  overflow: hidden;
  @media (${breakpoints.tabletPortrait.min}) {
    justify-content: center;
    padding: 49px 0;
  }
  @media (${breakpoints.desktop.min}) {
    padding: 62px 0;
  }
`

const Brand = styled(motion.div)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 80px;
  grid-auto-rows: 80px;
  column-gap: 40px;
  min-width: 100%;

  img {
    opacity: 0.5;
    user-select: none;
    user-drag: none;

    &:hover {
      opacity: 1;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    column-gap: 60px;
  }
  @media (${breakpoints.desktop.min}) {
    grid-auto-columns: 120px;
    grid-auto-rows: 111px;
    column-gap: 98px;
  }
`

const Wrapper = styled.div`
  padding: 18px 0;
  h2 {
    display: block;
    color: ${({ theme }) => theme.colors.brand?.default ?? theme.colors.red};
    font-size: 24px;
    text-align: center;
    margin: 0;
  }
  @media (${breakpoints.desktop.min}) {
    padding: 32px 0;
    h2 {
      font-size: 34px;
    }
  }
`

const Brands = ({ images, className = '' }: Props) => {
  const speed = images?.length < 40 ? 700 : 200
  const total = images ? images.length * speed : 0

  return (
    <Wrapper className={className}>
      <Container>
        <Brand
          animate={{
            x: [0, -total],
          }}
          transition={{ ease: 'linear', duration: 400, loop: Infinity }}
        >
          {images?.map((image, i) => (
            <Image
              className={`${className}-image`}
              key={i}
              src={image.url}
              alt="brand"
              params={{
                q: 90,
              }}
            />
          ))}
          {images?.map((image, i) => (
            <Image
              className={`${className}-image`}
              key={i}
              src={image.url}
              alt="brand"
              params={{
                q: 90,
              }}
            />
          ))}
        </Brand>

        <Brand
          animate={{
            x: [0, -total],
          }}
          transition={{ ease: 'linear', duration: 750, loop: Infinity }}
        >
          {images?.reverse().map((image, i) => (
            <Image
              className={`${className}-image`}
              key={i}
              src={image.url}
              alt="brand"
              params={{
                q: 90,
              }}
            />
          ))}
          {images?.reverse().map((image, i) => (
            <Image
              className={`${className}-image`}
              key={i}
              src={image.url}
              alt="brand"
              params={{
                q: 90,
              }}
            />
          ))}
        </Brand>
      </Container>
    </Wrapper>
  )
}

export default Brands
