import React, { useState, useEffect } from 'react'
import { useAnimation, useMotionValue, motion, MotionValue } from 'framer-motion'
import styled from 'styled-components'
import { useDimensions, Anchor, toCssPrefix, ZIndex, breakpoints } from '@ecommerce/shared'
import { Props, fixMobileScrollTouchHandler } from './utils'
import { Icon } from '../Icon/Icon'
import Bullet from './Bullet'

const { cssPrefix, toPrefix } = toCssPrefix('FramerSlider__')

type ContainerProps = {
  areAllItemsShown: boolean
}

const Container = styled.div<ContainerProps>`
  width: inherit;
  overflow: hidden;
  position: relative;

  .${cssPrefix} {
    &inner {
      display: flex;
      align-items: center;
      position: relative;
      ${({ areAllItemsShown }) => areAllItemsShown && 'justify-content: center;'}
    }

    &track {
      overflow: visible;
      display: flex;
      width: fit-content;
      height: 100%;
    }

    &navigation {
      display: none;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.white};
      height: 35px;
      width: 35px;
      opacity: 0.5;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.red};
      user-select: none;
      outline: none;
      border: none;
      transition: all 0.5s;
      outline-style: none;
      cursor: pointer;
      -moz-outline-style: none;
      position: absolute;
      top: calc(50% - 17.5px);
      z-index: ${ZIndex.low};

      &.next {
        right: 0;
      }

      &.prev {
        left: 0;
      }

      :hover {
        background-color: ${(props) => props.theme.colors.red};
        opacity: 0.5;
      }
    }

    &bullets {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (${breakpoints.desktop.min}) {
      &navigation {
        display: flex;
      }
    }
  }
`

const FramerSlider = (props: Props) => {
  const {
    itemsCount,
    slideWidthMobile = 0,
    slideGapMobile = 0,
    children,
    className = '',
    useControls = true,
    useBullets = false,
    resetPosition = false,
    dragDirectionLock = false,
  } = props

  /**
   * Mobile Scrolling Management
   */
  const [allowScroll, setAllowScroll] = useState(false)

  // IMPORTANT: this disables touchmove events globally while draging this slider
  useEffect(() => {
    if (allowScroll) {
      document.documentElement.addEventListener('touchmove', fixMobileScrollTouchHandler)
      return () => {
        document.documentElement.removeEventListener('touchmove', fixMobileScrollTouchHandler)
      }
    }
  }, [allowScroll])

  const [trackRef, trackDimensions, measureTrack] = useDimensions<HTMLDivElement & { x: MotionValue<number> }>()
  const [containerRef, containerDimensions] = useDimensions<HTMLDivElement>()
  const [leftDragConstraint, setLeftDragConstraint] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [slideWidth, setSlideWidth] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [areAllItemsShown, setAreAllItemsShown] = useState(false)

  useEffect(() => {
    measureTrack()
  }, [itemsCount])

  useEffect(() => {
    if (containerDimensions.width) {
      setContainerWidth(containerDimensions.width)

      if (trackDimensions.width) {
        const calculateLeftDragConstraint = (trackWidthPx: number, containerWidthPx: number) => {
          let leftLimit = trackWidthPx - containerWidthPx
          if (leftLimit < 0) leftLimit = 0

          return -leftLimit
        }

        setLeftDragConstraint(calculateLeftDragConstraint(trackDimensions.width, containerDimensions.width))
      }
    }
  }, [containerDimensions.width, trackDimensions.width])

  const [currentXPosition, setCurrentXPosition] = useState(0)
  const controls = useAnimation()
  const handleX = useMotionValue(0)
  handleX.onChange(setCurrentXPosition)

  const transition = { duration: 0.4, type: 'tween' }

  const moveSlider = (position: number) => controls.start({ x: position, transition })

  const scrollSlider = ({ left }: { left?: boolean }) => {
    let newPosition = currentXPosition - containerWidth * (left ? -1 : 1)
    if (left && newPosition > 0) newPosition = 0
    else if (newPosition < leftDragConstraint) newPosition = leftDragConstraint

    moveSlider(newPosition)
  }

  useEffect(() => {
    if (resetPosition) {
      moveSlider(0)
    }
  }, [resetPosition])

  useEffect(() => {
    if (useBullets && trackDimensions.width) {
      setSlideWidth((trackDimensions.width ?? 0) / itemsCount)
    }
  }, [useBullets, trackDimensions.width, itemsCount])

  useEffect(() => {
    if (useBullets) {
      setActiveIndex(Math.round(Math.abs(currentXPosition / slideWidth)))
    }
  }, [useBullets, slideWidth, currentXPosition])

  useEffect(() => {
    if (containerDimensions.width && trackDimensions.width) {
      setAreAllItemsShown(containerDimensions.width > trackDimensions.width)
    }
  }, [trackDimensions.width, containerDimensions.width])

  const onBulletClick = (index: number) => moveSlider(-slideWidth * index)

  const leftButtonIsVisible = currentXPosition < -(slideWidthMobile + slideGapMobile)
  const rightButtonIsVisible = currentXPosition >= leftDragConstraint + 200

  return (
    <Container className={`${cssPrefix} ${className}`} areAllItemsShown={areAllItemsShown}>
      {leftButtonIsVisible && useControls ? (
        <Anchor
          onClick={() => scrollSlider({ left: true })}
          type="button"
          className={`${toPrefix('navigation prev')} ${className}-nav`}
        >
          <Icon iconId="chevron_left" />
        </Anchor>
      ) : null}
      <div className={`${toPrefix('inner')} ${className}-inner`} ref={containerRef}>
        <motion.div
          dragDirectionLock={dragDirectionLock}
          ref={trackRef}
          className={`${toPrefix('track')} ${className}-inner-track`}
          dragConstraints={{ right: 0, left: leftDragConstraint, top: 0, bottom: 0 }}
          drag="x"
          style={useControls ? { x: handleX } : undefined}
          animate={useControls ? controls : undefined}
          onDragStart={(event, info) => setAllowScroll(Math.abs(info.delta.y) > Math.abs(info.delta.x))}
        >
          {children}
        </motion.div>
      </div>
      {rightButtonIsVisible && useControls ? (
        <Anchor
          onClick={() => scrollSlider({ left: false })}
          type="button"
          className={`${toPrefix('navigation next')} ${className}-nav`}
        >
          <Icon iconId="chevron_right" />
        </Anchor>
      ) : null}
      {useBullets && itemsCount > 1 && (
        <div className={`${toPrefix('bullets')} ${className}-bullets`}>
          {Array(itemsCount)
            .fill(0)
            .map((_, i) => (
              <Bullet key={i} onClick={() => onBulletClick(i)} isActive={activeIndex === i} />
            ))}
        </div>
      )}
    </Container>
  )
}

export default FramerSlider
