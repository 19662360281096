import React from 'react'
import styled from 'styled-components'
import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import SectionTitle from './SectionTitle'
import Video from '../Video'
import Slider from '../FramerSlider'
import useQuery from '../../hooks/useQuery'
import videoQuery, { QueryVideoResult } from '../../graphql/videoQuery'

type Props = {
  listId: string
  className?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('VideosList__')

const Wrapper = styled.div`
  padding-bottom: 100px;
  padding-left: 20px;

  .${cssPrefix} {
    &slider {
      margin-top: 65px;
    }

    &slide {
      width: 300px;
      height: 250px;
      margin-right: 20px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.max}) {
    .${cssPrefix} {
      &slide-video {
        &-title {
          font-size: 17px;
        }

        &-description {
          font-size: 12px;
          bottom: 20px;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) and (${breakpoints.tabletLandscape.max}) {
    .${cssPrefix} {
      &slide-video {
        &-title {
          font-size: 24px;
        }

        &-description {
          font-size: 14px;
          bottom: 28px;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding-left: 25px;

    .${cssPrefix} {
      &slide {
        margin-right: 25px;
        width: 400px;
        height: 260px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding-left: 90px;

    .${cssPrefix} {
      &slider-nav.next {
        right: 65px;
      }

      &slide {
        margin-right: 45px;
        width: 640px;
        height: 415px;
      }
    }
  }
`

const VideosList = ({ className = '', listId }: Props) => {
  const { data, loading, error } = useQuery<QueryVideoResult>(videoQuery, { variables: { listId } })

  const orList = data?.orList
  const videos = orList?.itemsCollection.items

  if (!videos || error !== undefined || (!videos?.length && !loading)) return null

  return (
    <Wrapper className={`${cssPrefix} ${className}`}>
      {orList?.title && <SectionTitle color={orList?.titleColor} text={orList?.title} {...orList} />}
      <Slider itemsCount={videos?.length} className={toPrefix('slider')}>
        {videos?.map((video) => (
          <div key={video.sys.id} className={toPrefix('slide')}>
            <Video
              src={video.video.url}
              poster={video.videoPoster.url}
              title={video.title}
              description={video.videoDescription}
              className={toPrefix('slide-video')}
              preload="none"
            />
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default VideosList
