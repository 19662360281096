import { OrBrandsListFragment, OrListFragment } from './fragments'
import { BaseBlock, BlockType, ClientSideBrandsList } from './contentfulTypes'

export interface QueryBrandsListResult {
  orList: {
    blockType: BlockType.BRANDS_LIST
    itemsCollection: {
      items: ClientSideBrandsList[]
    }
  } & BaseBlock
}

export default `
  query BrandsListQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ...OrBrandsListFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${OrBrandsListFragment}
`
