export type Props = {
  children: React.ReactNode
  className?: string
  itemsCount: number
  slideWidthMobile?: number
  slideGapMobile?: number
  useControls?: boolean
  resetPosition?: boolean
  useBullets?: boolean
  dragDirectionLock?: boolean
}

export const fixMobileScrollTouchHandler = (event: TouchEvent) => {
  if (!event.cancelable) return
  event.stopPropagation()
}
