import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, Image, breakpoints } from '@ecommerce/shared'
import SectionTitle from './SectionTitle'
import Brands from '../Brands'
import useQuery from '../../hooks/useQuery'
import brandsListQuery, { QueryBrandsListResult } from '../../graphql/brandsListQuery'

const { cssPrefix, toPrefix } = toCssPrefix('MomentsBrandsList__')

type Props = {
  listId: string
}

const Wrapper = styled.div<{ hasBackground?: boolean; backgroundColor?: string }>`
  position: relative;
  min-height: 440px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: -1;
    opacity: ${({ hasBackground }) => (hasBackground ? 1 : 0)};
  }

  .${cssPrefix} {
    &title {
      margin: 0;
    }

    &brands {
      padding: 0;
    }

    &brands-image {
      opacity: 1;
      object-fit: contain;
    }

    &background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      object-fit: cover;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    .${cssPrefix} {
      &title {
        margin: 56px 0 0 0;
      }
    }
  }
`

const MomentsBrandsList = ({ listId }: Props) => {
  const { data, loading, error } = useQuery<QueryBrandsListResult>(brandsListQuery, { variables: { listId } })
  const orList = data?.orList
  const brandsCollection = orList?.itemsCollection?.items

  if (error !== undefined || (!brandsCollection?.length && !loading)) return null
  if (!brandsCollection) return null

  const brands = brandsCollection[0]?.brandsCollection?.items

  if (!brands) return null

  return (
    <Wrapper hasBackground={!!orList?.background} className={cssPrefix}>
      <div className={toPrefix('content')}>
        {orList?.background ? (
          <Image
            preload={false}
            className={toPrefix('background')}
            src={orList.background.url}
            alt={`moments-${orList?.title}`}
          />
        ) : null}
        {orList?.title && (
          <SectionTitle color={orList?.titleColor} className={toPrefix('title')} text={orList?.title} {...orList} />
        )}
        <Brands className={toPrefix('brands')} images={brands} />
      </div>
    </Wrapper>
  )
}

export default MomentsBrandsList
